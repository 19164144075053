<template>
  <div class="page-fixed">
    <!-- <div class="fixed-item">
      <i class="iconfont duozhi-icon-qq"></i>
      <div class="fixed-show">123456</div>
    </div> -->
    <div class="fixed-item">
      <i class="iconfont duozhi-icon-dianhua"></i>
      <div class="fixed-show">400-9699-225</div>
    </div>
    <div class="fixed-item">
      <i class="iconfont duozhi-icon-erweima1688"></i>
      <div class="fixed-show" style="padding: 0">
        <img src="@/assets/images/ewm.jpg" alt="">
      </div>
    </div>
    <a class="fixed-item" href="#">
      <i class="iconfont duozhi-icon-zhiding"></i>
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.page-fixed{
  position: fixed;
  width: 60px;
  right: 10px;
  top: 200px;
  z-index: 9999;
  .fixed-item{
    line-height: 60px;
    display: block;
    cursor: pointer;
    width: 60px;
    height: 60px;
    background: #f5f5f5;
    margin: 5px 0;
    border-radius: 5px;
    color: #b2b0b0;
    position: relative;
    transition: all .3s;
    .iconfont{
      font-size: 26px;

    }
    .fixed-show{
      display: flex;
      position: absolute;
      right: 110%;
      top: 0;
      max-width: 0;
      background: #2da0db;
      overflow: hidden;
      transition: all .3s;
      color:  white;
      white-space: nowrap;
      img{
        width: 200px;
      }
    }
  }
  .fixed-item:hover{
    background: #2da0db;
    border-radius: 0;
    color:  white;
    .fixed-show{
      padding: 0 20px;
      max-width: 200px;
      max-height: 200px;
    }

  }
}
@media screen and (max-width: 600px) {
  .page-fixed{
    width: 40px;
    .fixed-item{
      line-height: 40px;
      height: 40px;
      width: 40px;
      .fixed-show{
        img{
          width: 150px;
        }
      }
    }

  }
}

</style>